import React, { useEffect, useState } from 'react';
import Header from "../sections/header/header";
import Sidebar from "./sidebar";
import deposit from "../images/deposit.png";
import withdraw from "../images/withdraw.png";
import Paypal from "./paypal";
import { useTransaction } from "../contexts/transactionContext";
import TransactionCard from "./transactionCard";
import BackButton from "./backButton";
import { useStateValue } from '../contexts/StateProvider';
import { useList } from "react-firebase-hooks/database";
import { realTimeDatabase } from "../firebase/firebase";
import TransactionCardLoading from "./transactionCardLoading";
import { ModalComponent } from "../sections/modal/modal";
import { useForm } from "../contexts/formContext";

function Deposit() {
    const [{ user, userData }] = useStateValue()
    const [clickedDeposit, setClickedDeposit] = useState(false)
    const [amount, setAmount] = useState()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState('')
    const [transactionSnap, loading] = useList(realTimeDatabase.ref('Transaction').orderByChild('userId').equalTo(user?.uid))
    const { depositSuccess, depositError, setDepositError } = useTransaction()
    const [modalShow, setModalShow] = useState(false);
    const { setFormType } = useForm()


    useEffect(() => {
        setAmount(5)
        setClickedDeposit(false)
    }, [])

    const handleDepositProcess = async (e) => {

        // e.preventDefault()
        let validateAmount = await amountChange(amount);

        if (validateAmount) {
            setFormType('depositeForm')
            setModalShow(true)
        }
    }

    const handleWithdrawProcess = (e) => {

        if (amount && amount > userData.userBalance) {
            setError('Withdrawal amount exceeds your balance')
        }
        else if (amount <= 0) {
            setError('Withdrawal amount cannot be less than or equal to $0')
        }
        else {
            e.preventDefault()
            setFormType('withdrawForm')

            setModalShow(true)
        }
    }


    const amountChange = (val) => {

        let regex = /^[0-9]+$/;

        // if (!regex.test(val)) {
        //     setError("Please enter valid amount.");
        //     return false;
        // }
        // else
        if (val < 5) {
            setError("Please enter an amount of 5.00 or greater.")
            return false;
        }
        else {
            setError("")
            setAmount(parseFloat(val).toFixed(2))
            return true;
        }

    }
    return (
        <>
            <Header />
            <div className={` deposit userData container`}>
                <div className={`d-flex align-items-center`}>
                    <div className={`lg-view`}>

                        <Sidebar />
                    </div>

                    <div className='text-light flex-column d-flex flex-grow-1 align-items-center justify-content-center'>
                        <div className={`mt-4 mr-auto`}>
                            <BackButton />
                        </div>
                        <div className={`icon-wrapper`}>

                        </div>
                        <h3>$ {userData?.userBalance}</h3>
                        <h5 className={``}>Total balance</h5>
                        <p className={`text-success`}>{success}</p>
                        <p className={`text-danger`}>{error}</p>
                        {/*Show form input when deposit button is clicked*/}
                        <form action="">
                            <input defaultValue={amount} className={`${depositError === "" ? 'text-light' : 'text-danger'}`} placeholder={`Amount in USD`} onChange={(e)=>{setAmount(e.target.value)}} type="number" />
                        </form>
                        <button disabled={loading} onClick={() => {
                            handleDepositProcess()
                        }} style={{ backgroundImage: `url(${deposit})` }} className={`btn w-50 icon-btn btn-clear`}>
                            {!loading ? 'Deposit' : 'Loading...'}

                        </button>
                        <button disabled={loading} onClick={handleWithdrawProcess}
                            style={{ backgroundImage: `url(${withdraw})` }}
                            className={`btn w-50  text-danger border-danger icon-btn btn-clear mt-3`}>
                            {!loading ? 'Withdraw' : 'Loading...'}
                        </button>
                        <div className='menu-options transactions container-fluid'>
                            {
                                !loading && transactionSnap ? transactionSnap?.reverse().map((transaction) => {
                                    return (

                                        transaction.val().transactionType === 'Deposit' && <TransactionCard image={deposit} details={transaction.val()} key={transaction.val().transactionId} />
                                    )
                                })
                                    : <>
                                        <TransactionCardLoading image={deposit} />
                                        <TransactionCardLoading image={deposit} />
                                        <TransactionCardLoading image={deposit} />
                                        <TransactionCardLoading image={deposit} />

                                    </>

                            }


                        </div>
                        <ModalComponent
                            amount={amount}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
}

export default Deposit;