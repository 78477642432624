import './css/Home.css';
import Hero from './sections/hero/hero';
import Features from './sections/features/features';
import Performance from './sections/performance/performance';
import DownloadLinks from './sections/downloadLinks/downloadLinks';
import Testimonials from './sections/testimonials/testimonials';
import ContactUs from './sections/contactUs/contactUs';
import Footer from './sections/footer/footer';
import { useLocation } from 'react-router-dom';
import React, {useEffect,useState} from 'react';

import './css/style.css'

function Home() {

    var location = useLocation();
    useEffect(()=>{
        if(location.hash){
            myfun(location.hash);
        }
    },[location])

    var myfun = function(ele){

        var element = document.querySelector(ele);
        if (element) {
            element.scrollIntoView();
        }
    }

    return (
        <>

        <div className="home">
            <Hero/>
            <Features/>
            <Performance/>
            <DownloadLinks/>
            <ContactUs/>
            <Footer/>
        </div>

        </>

  );
}

export default Home;
