import React, { useState, useEffect, forwardRef } from 'react';
import '../../utility/helper'
import { updateFirebaseDocument, updateFirestoreDocument } from "../../utility/helper";
import { useLoader } from "../../contexts/loaderContext";
import { auth, db, storage } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth"
import { useStateValue } from '../../contexts/StateProvider';
import { useCollection, useDocumentOnce } from "react-firebase-hooks/firestore"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase";
import { useDebugValue } from 'react';
import moment from 'moment';
// import {useAuthState} from "react-firebase-hooks/auth"  
// import calendar from "../../images/calender.png";

export default function EventSCoreDateTime(props) {

    const [currentUser] = useAuthState(auth);
    const [authUser, setAuthUser] = useState({});
    const [firstUser, setFirstUser] = useState({});
    const [secondUser, setSecondUser] = useState({});
    const { setLoader } = useLoader();
    const [user] = useAuthState(auth);
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [{ userData }, dispatch] = useStateValue()
    const [startDate, setStartDate] = useState(new Date());
    var today = new Date();
    const [endDate, setEndDate] = useState(today.setDate(today.getDate() + 1));
    const [eventId] = useState(props?.eventId);
    const [scheduleData] = useState(props?.scheduleData);
    const [activeTab] = useState(props?.activeTab);
    const [eventData] = useState(props?.eventData);
    const [chatRoomSnap, setChatRoomSnap] = useState(null);

    // console.log(props?.scheduleData)

    useEffect(() => {
        db.collection("ChatRooms").doc(eventId).get().then((chatRoomSnap) => {
            setChatRoomSnap(chatRoomSnap.data());
        })
    }, [])

    const onCancel = () => {
        try {
            props.onHide(true);
        } catch (err) { console.warn('Error in On Cancel Function :-', err) }
    }

    const CustomDate = forwardRef(({ value, onClick }, ref) => (
        <>
            <div className={`w-100 date pointer d-flex align-items-center justify-content-center`} style={{ borderRadius: '5px' }} onClick={onClick} ref={ref} name="date"
            // style={{ backgroundImage: `url(${calendar})` }}
            >{value}</div>
        </>
    ));

    useEffect(() => {
        db.collection('User').where('objectId', "==", scheduleData.FirstUserId).onSnapshot(async (snapshot) => {
            var firstUser = await snapshot.docs[0].data();
            await setFirstUser(firstUser);

            if(currentUser.uid == scheduleData.FirstUserId){
                await setAuthUser(firstUser);
            }
        });
        
        db.collection('User').where('objectId', "==", scheduleData.SecondUserId).onSnapshot(async (snapshot) => {
            var secondUser = await snapshot.docs[0].data();
            await setSecondUser(secondUser);

            if(currentUser.uid == scheduleData.SecondUserId){
                await setAuthUser(secondUser);
            }
        });
        
        if (startDate < 0) {
            setStartDate(new Date())
            setEndDate(new Date().setDate(today.getDate() + 1))
        } else {
            setError('')
        }
        return () => null
    }, [startDate])

    const handleClick = () => {
        setFile('')
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        setFile(event.target.files[0])
    };

    const handleSubmit = async (e) => {
        setError('')
        setLoading(true);
        const uuid = uuidv4().replace(/-/g, '').toUpperCase();
        // console.log("auth++++++++++++", authUser, eventId, chatRoomSnap);
        let startDataTimeStamp = moment(startDate).format('X');
        var participants = chatRoomSnap?.participants;

        let isAuthUserFirst = false;
        let isFirstUserParticipate = false;
        let isSecondUserParticipate = false;

        var oppositeUserId = "";
        if (authUser.objectId == scheduleData.FirstUserId) {
            isAuthUserFirst = true;
            oppositeUserId = scheduleData.SecondUserId;
        }
        else{
            oppositeUserId = scheduleData.FirstUserId;
        }

        participants.map((userData, index) => {
            if (userData.objId == scheduleData.FirstUserId) {
                isFirstUserParticipate = true;
            } else if (userData.objId == scheduleData.SecondUserId) {
                isSecondUserParticipate = true;
            }
        })

        // console.log(isOppositeUser)
        // let isOppositeUser = participants.find(({ objId }) => objId == scheduleData?.[isAuthUserFirst ? 'SecondUserId' : 'FirstUserId']);

        if (!isFirstUserParticipate) {
            participants.push({
                email: firstUser.email,
                gamerTag: firstUser.gamerTag,
                objId: firstUser?.objectId,
                userProfileImageUrl: firstUser?.userProfileImageUrl
            });
        }
        if (!isSecondUserParticipate) {
            participants.push({
                email: secondUser.email,
                gamerTag: secondUser.gamerTag,
                objId: secondUser?.objectId,
                userProfileImageUrl: secondUser?.userProfileImageUrl
            });
        }

        var chatRoomData = {
            chatId: uuid,
            text: "",
            senderEmail: authUser.email,
            senderObjId: authUser.objectId,
            userProfileImageUrl: authUser?.userProfileImageUrl,
            senderName: authUser?.gamerTag,
            isDelivered: false,
            isImage: false,
            isVideo: false,
            isVoiceNote: false,
            storageMediaUrl: '',
            deliveredToParticipants: oppositeUserId,
            chatRoomId: eventId,
            dateTime: firebase.firestore.FieldValue.serverTimestamp(),
        };

        if(isAuthUserFirst){
            chatRoomData['firstUserTimeProposal'] = startDataTimeStamp;
        }
        else{
            chatRoomData['secondUserTimeProposal'] = startDataTimeStamp;
        }

        await db.collection('ChatRooms').doc(eventId).update({participants: participants});

        db.collection('ChatRooms').doc(eventId).collection('chat').doc(uuid).set(chatRoomData).then( () => {
                // Update chatroom dateLastUpdated field
            db.collection('ChatRooms').doc(eventId).update({dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {

                db.collection('User').doc(currentUser.uid).collection('ChatRoomIds').add({
                    id: eventId,
                    isDeleted: false,
                    isDelivered: false 
                });

                db.collection('User').doc(oppositeUserId).collection('ChatRoomIds').add({
                    id: eventId,
                    isDeleted: false,
                    isDelivered: false 
                });

                // Increment the receiver notificationCount field in Users Collection
                participants.filter(each => {
                    if (each.objId !== currentUser.uid){
                        
                        db.collection('User').doc(oppositeUserId).update({notificationCounts: firebase.firestore.FieldValue.increment(1)});

                        
                    }

                })


                // setInput('')
                // Scroll to the last text
                // scrollView.current.scrollIntoView({behavior: 'smooth'})
            })
            .catch(e => {
                console.log(e.message)
            })
            props.onHide(true);
                // setLoading(false)

        })
        .catch(e => {
            console.log(e.message)
        })

        setLoading(false);
    }

    return (
        <>
            <form className="form justify-content-around flex-column px-3" >
                <div className="d-flex justify-content-center">
                    <DatePicker
                        className={`datepicker`}
                        customInput={<CustomDate />}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                    />
                </div>
                <div className='d-flex flex-row justify-content-between align-item-center'>
                    <button onClick={() => { onCancel() }} style={{ width: '42%', maxHeight: '36px', borderRadius: '10px', background: '#ffffff', textTransform: 'capitalize' }} type='button'>
                        <span className='form-btn'>{'Cancel'}</span>
                    </button>
                    <button style={{ width: '42%', maxHeight: '36px', borderRadius: '10px', textTransform: 'capitalize' }} type='button' onClick={handleSubmit}>
                        <span className='form-btn'>{'Send'}</span>
                    </button>
                </div>
            </form>
        </>
    );

}