import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import Signup from "../signup/signup";
import facebook from "../../images/facebook.svg";
import google from "../../images/google.svg";
import './modal.css'
import Login from "../login/login";
import ForgotPassword from "../../utility/resetPassword";
import {useForm} from "../../contexts/formContext";
import EditProfile from '../../sections/forms/editProfileForm'
import back_arrow from "../../images/back_arrow.svg";
import Withdraw from '../withdraw/withdraw';
import Deposit from '../deposit/deposit';
import EventScore from '../eventScore/eventScore';
import EventScoreDateTime from '../eventScore/eventScoreDateTime';
import MessageModal from './messageModal';

// Modal content controlled by state
function ModalComponent(props) {
    const [showForm, setShowForm] = useState({login: true, signup: false, forgotPassword: false, eventForm : false});
    const {formType,setFormType}= useForm()
    document.documentElement.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'auto';
    const [showLoginLink, setLoginStatus] = useState('true');

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal"
            className={`custom-modal ${formType === 'depositeForm' ? 'deposit-modal' : ''}`} //Add class name here

        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img onClick={() => props.onHide()} className='sm-view  pointer back-arrow' src={back_arrow} alt=""/>
                {formType === 'loginForm' ?
                    <>
                    <MessageModal {...props} message='Feature coming soon' type='message' />
                        {/* <Login/> */}
                        {/* <p className='pt-3 form-text f-18'> Don't have an account? <span
                            onClick={() => {setShowForm({login: false, signup: true, forgotPassword: false});setFormType('signupForm')}}
                            className="login-text pointer">Sign Up</span></p>
                        <p className='text-light pointer' onClick={() => {setShowForm({login: false, signup: false, forgotPassword: true});setFormType('forgotForm')}}>Forgot Password</p> */}
                    </>
                    : formType === 'signupForm' ?
                        <>

                            <Signup/>
                            <p className='pt-3 form-text f-18'>Have an account? <span onClick={() => {setShowForm({
                                signup: false,
                                login: true,
                                forgotPassword: false
                            });setFormType('loginForm')}} className="login-text pointer">Log In</span></p>
                        </>
                        : formType === 'forgotForm' ?
                            <>
                                <ForgotPassword setLoginStatus={(status)=>{setLoginStatus(status);}} {...props}/>
                                {
                                    (showLoginLink == "true")&&
                                    <p className='pt-3 form-text f-18'>Have an account? <span onClick={() => { setShowForm({
                                        signup: false,
                                        login: true,
                                        forgotPassword: false
                                    });setFormType('loginForm')}} className="login-text pointer">Log In</span></p>
                                }
                                
                            </>

                                : formType === 'eventScoreDateTime' ? <EventScoreDateTime {...props}/>
                                : formType === 'eventScore' ? <EventScore {...props}/>
                                : formType === 'editProfile' ? <EditProfile/>
                                : formType === 'depositeForm' ? <Deposit {...props} />
                                : formType === 'messageModal' ? <MessageModal {...props} />
                                : formType === 'withdrawForm' && <Withdraw {...props} />}


            </Modal.Body>
            <Modal.Footer>




                <h6 className='d-none w-100  social-header'>CONNECT WITH</h6>
                <div className='d-none social-icons-container '>
                    <div className='social-icon '>
                        <img className="text-center" src={facebook} alt=""/>
                    </div>
                    <div className='social-icon '>
                        <img className="text-center" src={google} alt=""/>
                    </div>

                </div>

            </Modal.Footer>
        </Modal>
    );
}

// Buttons that will trigger the modal
function LoginBtn() {
  const {setFormType}= useForm()
  const [modalShow, setModalShow] = React.useState(false);



  const  HandleLogin = () =>{
    setFormType('loginForm')

    setModalShow(true)
  }

    return (
        <>
            <button onClick={HandleLogin} className="lg-view btn ml-2 btn-clear">Login</button>
            <a onClick={HandleLogin} className="sm-view nav-link" href="#">Login</a>


            <ModalComponent
                show={modalShow}
                onHide={() => setModalShow(false) }
            />
        </>
    );
}

function EditProfileBtn() {
    const {setFormType}= useForm()
    const [modalShow, setModalShow] = React.useState(false);



    const  editProfile = () =>{
        setFormType('editProfile')

        setModalShow(true)
    }


    return (
        <>

            <div onClick={editProfile } style={{backgroundColor: '#13161A'}} className=' pointer'>
                <svg className={`edit-profile`} xmlns="http://www.w3.org/2000/svg" width="59.903" height="59.903" viewBox="0 0 59.903 59.903">
                    <path id="Path_41" data-name="Path 41" d="M52.427,32.287l-4.713-4.713L16.667,58.62v4.713H21.38Zm4.713-4.713,4.713-4.713L57.14,18.147,52.427,22.86ZM24.14,70H10V55.857L54.783,11.073a3.333,3.333,0,0,1,4.713,0l9.43,9.43a3.333,3.333,0,0,1,0,4.713L24.143,70Z" transform="translate(-10 -10.097)" fill="#18ff00"/>
                </svg>
            </div>

            <ModalComponent
                show={modalShow}
                onHide={() => setModalShow(false) }
            />
        </>
    );
}


export  {EditProfileBtn, LoginBtn, ModalComponent}
