import React from 'react';
import '../sections/profile/userProfile.css'
import Bar from "./bar";
import {useParams} from "react-router-dom";
import {useStateValue} from '../contexts/StateProvider';

function Graph()  {
    const [{user,userData}] = useStateValue()
    let params = useParams();


    return (
        <div className="graph p-4">
            <div className={`d-flex text-light`}>
                <h4>Winning ratio</h4>{
                params.id === user?.uid &&   <span className={`ml-auto`}>$ {userData && userData.userBalance}</span>

            }

            </div>
            <div className={` d-flex `}>
                <Bar value={60} day={'MON'}/>
                <Bar value={60} day={'TUE'}/>
                <Bar value={60} day={'WED'}/>
                <Bar value={60} day={'THUR'}/>
                <Bar value={60} day={'FRI'}/>
                <Bar value={60} day={'SAT'}/>
                <Bar value={60} day={'SUN'}/>

            </div>

        </div>
    );
}

export default Graph;