import React from 'react';
import Header from "../header/header";

function HowItWorks() {
    return (
        <div>
            <Header />
        </div>
    );
}

export default HowItWorks;