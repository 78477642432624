import React, {useEffect,useState} from 'react';
import {getRealtimeChild} from "../utility/helper";
import Header from "../sections/header/header";
import Sidebar from "./sidebar";
import Card from "./card";
import BackButton from "./backButton";
import {useStateValue} from '../contexts/StateProvider';

function CreatedEvents() {
    const [{user}] = useStateValue()
    const [createdEvents,setCreatedEvents] = useState([])
    const [loading,setLoading] = useState(true)
    let createdEventsList = [];


    useEffect(()=>{
        if(user.uid){

            // Fetch events created by the current user
            // This function is exported from utility/helper.js
            getRealtimeChild('Exhibitions','ExhibitionCommissionerId',user.uid).get()
            .then((snapshot)=>{
                snapshot.forEach((doc) =>{

                    // Append the document key to the doc.val() object as an id property
                    createdEventsList.push(Object.assign(doc.val(), {id:doc.key}))
                })
                setCreatedEvents(createdEventsList.reverse())
                setLoading(false)

            })
            .catch(e => {
                console.log(e)
                setLoading(false)

            })
        }
    },[])

    return (
        <>
            <Header/>
            <div className={` user container`}>
                <div className={`d-flex`}>
                    <div className={`lg-view`}>

                        <Sidebar/>
                    </div>
                    <div className='flex-column flex-grow-1'>
                        <div className={`mt-4 sm-view`}>
                            <BackButton/>
                        </div>

                        <div className='grid-container'>
                            {createdEvents ? createdEvents.map(event => {
                                return (
                                    <>

                                        <Card event={event} key={event.id} id={event.id}/>
                                    </>
                                )
                            })
                                : loading ?
                                <>
                                    <Card event={''} key={1}/>
                                    <Card event={''} key={2}/>
                                    <Card event={''} key={3}/>
                                </>

                                :<></>
                            }
                        </div>


                    </div>
                </div>

            </div>

        </>


    );
}

export default CreatedEvents;