import React, { useState } from 'react';
import rank from "../images/rank.svg";
import arrow from "../images/arrow right.svg";
import { NavLink, useHistory } from "react-router-dom";
import { EditProfileBtn } from "../sections/modal/modal";
import Graph from "./graph";
import { useStateValue } from '../contexts/StateProvider';
import { auth } from "../firebase/firebase";

function Sidebar() {
    const [{ user, userData }, dispatch] = useStateValue();

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleLogOut(e) {
        e.preventDefault()
        try {

            await auth.signOut()
            dispatch({
                type: "SET_USER",
                user: {}
            })
            history.push('/')

        } catch (err) {
            setError(err.message)
            setLoading(false)

        }

    }

     return (
        <>

            <div className='d-flex flex-grow-1 flex-column  mr-lg-4 mr-xl-4 mr-md-4 card-body user-side-bar'>
                <div className='user-sidebar-header'>
                    <div className='flex-column'>
                        <h5 className='text-light'>Your Profile</h5>

                        <p className='grey-text'>Manage Your Account Info</p>

                    </div>
                    <div className=' ml-auto pointer menu-icon-wrapper text-center '>
                        <EditProfileBtn />

                    </div>

                </div>
                <div className='text-center  user-info-container'>
                    {
                        userData != null ?
                            (!("youtubelink" in userData) || !("twitchlink" in userData)) ?
                                <h5 className='text-warning'>Please complete your profile</h5>
                                :
                                ((userData.youtubelink.trim() == "") || (userData.twitchlink.trim() == "") || (userData.youtubelink.trim() == "NONE") || (userData.twitchlink.trim() == "NONE")) &&
                                <h5 className='text-warning'>Please complete your profile</h5>
                            :
                            <></>

                    }
                    <div className='position-relative'>
                        <div className='mx-auto d-block user-profile-pic-wrapper'>

                            
                            <img src={userData && userData?.userProfileImageUrl || user && user.photoURL} alt="" />

                        </div>
                        <div className='badge-wrapper'>
                            <img src={rank} alt="" />
                            <span className='ml-2'>0</span>
                        </div>
                    </div>


                    <div className='mt-3 text-light'>
                        <div className='space-medium f-18'>{user?.displayName || userData?.gamerTag}</div>
                        <div className="space-light mb-4">@{user?.displayName || userData?.gamerTag}</div>

                    </div>
                    <div className='sm-view'>
                        <Graph />

                    </div>

                </div>

                <div className='menu-options'>

                    {/* <NavLink activeClassName="active" exact={true} to={`/user/${user?.uid}/joined-events`} className=' m-3 pointer  menu-item'>
                        <div className='icon-wrapper'>
                            <div className='center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                    <path id="Path_47" data-name="Path 47" d="M42.5,7.5h10A2.5,2.5,0,0,1,55,10V50a2.5,2.5,0,0,1-2.5,2.5H7.5A2.5,2.5,0,0,1,5,50V10A2.5,2.5,0,0,1,7.5,7.5h10v-5h5v5h15v-5h5Zm-5,5h-15v5h-5v-5H10v10H50v-10H42.5v5h-5ZM50,27.5H10v20H50Z" transform="translate(-5 -2.5)" fill="#18ff00" />
                                </svg>
                            </div>
                        </div>
                        <div className='ml-3'>
                            <div className=' space-light'>Joined Exhibitions</div>
                        </div>
                        <div className='mr-0 ml-auto'>
                            <img src={arrow} alt="" />
                        </div>
                    </NavLink> */}
                    {/* <NavLink activeClassName="active" exact={true} to={`/user/${user?.uid}/created-events`} className='menu-item m-3 pointer '>
                        <div className='icon-wrapper'>
                            <div className='center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                    <path id="Path_49" data-name="Path 49" d="M22.5,2.5v5h15v-5h5v5h10A2.5,2.5,0,0,1,55,10V50a2.5,2.5,0,0,1-2.5,2.5H7.5A2.5,2.5,0,0,1,5,50V10A2.5,2.5,0,0,1,7.5,7.5h10v-5ZM50,25H10V47.5H50ZM37.59,27.84l3.535,3.535L28.75,43.75l-8.84-8.84,3.54-3.535,5.3,5.305,8.84-8.84ZM17.5,12.5H10V20H50V12.5H42.5V15h-5V12.5h-15V15h-5Z" transform="translate(-5 -2.5)" fill="#18ff00" />
                                </svg>
                            </div>
                        </div>
                        <div className='ml-3'>
                            <div className=' space-light'>Created Exhibitions</div>
                        </div>
                        <div className='mr-0 ml-auto'>
                            <img src={arrow} alt="" />
                        </div>
                    </NavLink> */}
                    {/* {user?.email.includes('@clutchtime.com') && */}
                        <NavLink activeClassName="active" exact={true} to={`/user/${user?.uid}/create-schedule`} className='menu-item m-3 pointer'>
                            <div className='icon-wrapper'>
                                <div className='center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                        <path id="Path_51" data-name="Path 51" d="M42.5,7.5h10A2.5,2.5,0,0,1,55,10V50a2.5,2.5,0,0,1-2.5,2.5H7.5A2.5,2.5,0,0,1,5,50V10A2.5,2.5,0,0,1,7.5,7.5h10v-5h5v5h15v-5h5Zm7.5,15v-10H42.5v5h-5v-5h-15v5h-5v-5H10v10Zm0,5H10v20H50Zm-35,5H27.5v10H15Z" transform="translate(-5 -2.5)" fill="#18ff00" />
                                    </svg>
                                </div>
                            </div>
                            <div className='ml-3'>
                                <div className=' space-light'>Exhibitions</div>
                                {/* <div className=' space-light'>Create Schedule</div> */}
                            </div>
                            <div className='mr-0 ml-auto'>
                                <img src={arrow} alt="" />
                            </div>
                        </NavLink>
                    {/* } */}
                    {/* <NavLink activeClassName="active" exact={true} to={`/user/${user?.uid}/deposit`} className='menu-item m-3 pointer '>
                        <div className='icon-wrapper'>
                            <div className='center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="57.6" height="60" viewBox="0 0 57.6 60">
                                    <path id="Path_53" data-name="Path 53" d="M38.4,7.2A34.741,34.741,0,0,0,25.425,9.825L23.1,10.8l1.125,2.25,3.825,7.575C22.931,23.644,9.6,32.887,9.6,48c0,7.087,2.3,12.469,7.275,15.45S28.828,67.2,38.4,67.2s16.547-.769,21.525-3.75S67.2,55.087,67.2,48c0-9.863-5.756-17.25-11.175-22.05A19.815,19.815,0,0,1,63,30.45l3.6-3.3a29.64,29.64,0,0,0-17.25-7.725l3.225-6.375L53.7,10.8l-2.325-.975A34.743,34.743,0,0,0,38.4,7.2Zm0,4.8a27.345,27.345,0,0,1,8.625,1.35L44.1,19.2H32.7l-2.925-5.85A27.342,27.342,0,0,1,38.4,12ZM31.95,24h12.9c.591.309,17.55,9.253,17.55,24,0,6.169-1.434,9.216-4.95,11.325S47.672,62.4,38.4,62.4s-15.534-.966-19.05-3.075S14.4,54.169,14.4,48C14.4,33.253,31.359,24.309,31.95,24ZM36,28.8v2.85a7.254,7.254,0,0,0-4.8,6.75,6.338,6.338,0,0,0,2.325,4.725,19.026,19.026,0,0,0,3.825,2.25,18.082,18.082,0,0,1,2.85,1.65c.581.478.6.609.6.975a2.455,2.455,0,0,1-2.4,2.4A2.358,2.358,0,0,1,36,48H31.2A7.226,7.226,0,0,0,36,54.75V57.6h4.8V54.75A7.25,7.25,0,0,0,45.6,48a6.225,6.225,0,0,0-2.4-4.65,18.561,18.561,0,0,0-3.75-2.25,15.567,15.567,0,0,1-2.775-1.65C36.094,38.963,36,38.8,36,38.4A2.417,2.417,0,0,1,38.4,36a2.358,2.358,0,0,1,2.4,2.4h4.8a7.226,7.226,0,0,0-4.8-6.75V28.8Z" transform="translate(-9.6 -7.2)" fill="#18ff00" />
                                </svg>

                            </div>
                        </div>
                        <div className='ml-3'>
                            <div className=' space-light'>Deposit Funds</div>
                        </div>
                        <div className='mr-0 ml-auto'>
                            <img src={arrow} alt="" />
                        </div>
                    </NavLink> */}
                    <NavLink activeClassName="active" exact={true} to={`/user/${user?.uid}/manage-funds`} className='menu-item m-3 pointer'>
                        <div className='icon-wrapper'>
                            <div className='center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="59.921" height="60" viewBox="0 0 59.921 60">
                                    <path id="Path_52" data-name="Path 52" d="M42.5,10a9.979,9.979,0,0,0-4.922,18.672A11.789,11.789,0,0,0,37.5,30,12.5,12.5,0,1,0,52.188,17.656,9.986,9.986,0,0,0,42.5,10Zm0,5a4.962,4.962,0,0,1,4.531,2.891A12.591,12.591,0,0,0,39.3,23.672,4.725,4.725,0,0,1,37.5,20,4.96,4.96,0,0,1,42.5,15ZM50,22.5A7.5,7.5,0,1,1,42.5,30,7.465,7.465,0,0,1,50,22.5ZM28.672,40c-7.988,0-18.594,4.375-18.594,4.375l1.875,4.688S22.7,45,28.672,45c2.422,0,6.552,1.484,9.922,3.2a44.592,44.592,0,0,1,4.3,2.5c.557.371,1.025.7,1.328.938a3,3,0,0,1,.313.313l.391.547a1.878,1.878,0,0,1-2.344,2.422H42.5L31.641,49.688l-2.109,4.531,11.094,5.313H40.7l.156.078a6.866,6.866,0,0,0,7.891-2.5,6.637,6.637,0,0,0,1.094-4.687l12.031-2.031a2.678,2.678,0,0,1,2.969,1.875,2.617,2.617,0,0,1-1.484,3.281c-3.662,1.611-9.551,4.023-14.844,6.016-2.646,1-5.176,1.875-7.109,2.5-.967.313-1.807.547-2.422.7S37.871,65,38.125,65a19.144,19.144,0,0,1-2.031-.859c-1.182-.635-2.666-1.562-4.141-2.5s-3-1.885-4.453-2.656A9.281,9.281,0,0,0,22.969,57.5a22.1,22.1,0,0,0-7.734,1.563l1.953,4.609A19.085,19.085,0,0,1,22.969,62.5a11.326,11.326,0,0,1,2.188.859c1.191.635,2.666,1.563,4.141,2.5s2.949,1.885,4.375,2.656A8.87,8.87,0,0,0,38.125,70a7.149,7.149,0,0,0,2.109-.391c.771-.205,1.709-.449,2.734-.781,2.051-.664,4.57-1.562,7.266-2.578,5.381-2.021,11.347-4.414,15.156-6.094a7.722,7.722,0,0,0-4.375-14.687L47.344,47.734c-.01-.01-.058.01-.078,0-.078-.059-.156-.176-.234-.234l-.7-.547v.078c-.215-.156-.371-.3-.625-.469a51.243,51.243,0,0,0-4.844-2.812C37.139,41.856,32.773,40,28.672,40Z" transform="translate(-10.078 -10)" fill="#18ff00" />
                                </svg>

                            </div>
                        </div>
                        <div className='ml-3'>
                            <div className=' space-light'>Manage Funds</div>
                        </div>
                        <div className='mr-0 ml-auto'>
                            <img src={arrow} alt="" />
                        </div>
                    </NavLink>


                    <div className={'sm-view-flex'}>
                        <button onClick={handleLogOut} className={`btn btn-clear m-3 w-100 `}>Logout</button>

                    </div>
                    <div className={`sm-view`}>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>


            </div>


        </>

    );
}

export default Sidebar;