import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import hero_mobile from '../../images/hero_mobile.png';

function PrivacyPolicy() {

    return (
        <>
            <Header />
            <div className="container pt-3 pb-5">
                <h3 className='text-center font-weight-bold pb-5' style={{ color: '#18ff00' }}>Privacy and Policy</h3>
                <p className='text-white font-weight-normal text-justify mt-2 mb-4' style={{ verticalAlign: 'center', fontSize: '14.4px' }}>
                    Clutch Time Inc. (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”). Please read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION. 
                </p>
                <ul style={{ listStyleType: 'disc' }}>
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.  
                            </li>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you.  We are not responsible for any of the data collected by any such third party. 
                            </li>
                        </ul>

                    <li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>COLLECTION OF YOUR INFORMATION
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                We may collect information about you in a variety of ways.  The information we may collect via the Application depends on the content and materials you use, and includes:  
                            </li>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Personal Data </h5>
                                Demographic and other personally identifiable information (such as your name, age, date of birth, address, telephone number, email address, user game console, gamer tag and YouTube/twitch channel tag) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as chat, posting messages in comment sections or in our forums, liking posts, sending feedback, and responding to surveys.  If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Application.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Derivative Data  </h5>
                                Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Financial Data  </h5>
                                Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Application. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, Braintree Payments,Paypal,Stripe and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Geo-Location Information</h5> 
                                We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Mobile Device Access</h5>  
                                We may request access or permission to certain features from your mobile device, including your mobile device’s bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Push Notifications</h5>   
                               We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>USE OF YOUR INFORMATION
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the Application to:  
                            </li>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								Administer promotions, and contests.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Assist law enforcement and respond to subpoena. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
							 	Compile anonymous statistical data and analysis for use internally. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Create and manage your account.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Email you regarding your account or order.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Enable user-to-user communications.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Fulfill and manage purchases, orders, payments, and other transactions related to the Application.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Generate a personal profile about you to make future visits to the Application more personalized.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Increase the efficiency and operation of the Application.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Monitor and analyze usage and trends to improve your experience with the Application.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Notify you of updates to the Application.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Offer new products, services, mobile applications, and/or recommendations to you.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Perform other business activities as needed
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Process payments and refunds.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Request feedback and contact you about your use of the Application.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Resolve disputes and troubleshoot problems.
							</li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								Respond to product and customer service requests.
							</li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>DISCLOSURE OF YOUR INFORMATION
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                            </li>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								<h5>By Law or to Protect Rights</h5> 
                                If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Third-Party Service Providers</h5>
								We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.  
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Interactions with Other Users</h5> 
								If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Online Postings</h5> 
								When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Mobile Device Access</h5>  
                                We may request access or permission to certain features from your mobile device, including your mobile device’s bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Affiliates</h5>   
							   We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Business Partners</h5>   
							   We may share your information with our business partners to offer you certain products, services or promotions.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Sale or Bankruptcy</h5>   
							   If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
								
								We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations.  If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly. 
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Business Partners</h5>   
							   Website Analytics 
								We may also partner with selected third-party vendors, such as, Google Analytics to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can install and/or update your settings for one of the following: Google Analytics Opt-Out Plugin, Google Ads Settings Page. You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.

                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Business Partners</h5>   
							   We may share your information with our business partners to offer you certain products, services or promotions.
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>THIRD-PARTY WEBSITES
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								The Application may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Application.
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>SECURITY OF YOUR INFORMATION
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.  Therefore, we cannot guarantee complete security if you provide personal information.
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>POLICY FOR MINORS
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
								We do not knowingly solicit information from or market to minors. If you become aware of any data we have collected from minors, please contact us using the contact information provided below. 
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>OPTIONS REGARDING YOUR INFORMATION
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                Account Information:
                            </li>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                You may at any time review or change the information in your account or terminate your account by:
								<ul>
								<li>Logging into your account settings and updating your account</li>
								<li>Contacting us using the contact information provided below</li>
								</ul>
								Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
								<h5>Emails and Communications</h5>
								If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
								<ul>
								<li>Noting your preferences at the time you register your account with the Application.</li>
								<li>Logging into your account settings and updating your preferences.</li>
								<li>Contacting us using the contact information provided below
								If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.</li>
								</ul>
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>CALIFORNIA PRIVACY RIGHTS
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
                                If you are under 18 years of age, reside in California, and have a registered account with the Application, you have the right to request removal of unwanted data that you publicly post on the Application. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California.  We will make sure the data is not publicly displayed on the Application, but please be aware that the data may not be completely or comprehensively removed from our systems.
                            </li>
                        </ul>
                    </li>
					<li className='text-left text-white font-weight-bold' style={{ fontSize: '16px' }}>CONTACT US
                        <ul className='list-style pt-2 pl-4' style={{ fontSize: '14px' }}>
                            <li className='text-justify text-white font-weight-normal mb-2'>
                                If you have questions or comments about this Privacy Policy, please contact us at:
                            </li>
							<li className='text-justify text-white font-weight-normal mb-2'>
                                <p>Clutch Time Inc.</p>
								<p>105 N 5th st</p>
								<p>Emmaus, PA 18049</p>
								<p>info@clutchtime.com</p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Footer />
        </>
    );

}

export default PrivacyPolicy;