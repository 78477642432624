import React, { useRef, useState } from 'react';
import './signup.css';
import trophy from '../../images/trophy.svg';
import password from '../../images/password.svg';
import mail from '../../images/mail.svg';
import user from '../../images/user.svg';
import { pushData, updateDocument, setRealtimeDoc, setFirestoreDocument } from "../../utility/helper";
import firebase from "firebase";
import { auth, db } from '../../firebase/firebase';
import { useHistory } from "react-router-dom";


function Signup() {
    const usernameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [username, setUsername] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    let history = useHistory()


    async function signUp(e) {
        if (username.trim() == "") {
            setError('Please enter Game Tag')
            return ""
        }
        setError('')
        setLoading(true)

        const users = await db.collection('User').get();

        var username_exists_satus = false;
        var email_exists_status = false;
        users.forEach(user => {
            const usrData = user.data();
            if (usrData.userName && usrData.userName.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }
            else if (usrData.gamerTag && usrData.gamerTag.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }

            if (usrData.email?.toLowerCase() == emailRef.current.value.toLowerCase()) {
                email_exists_status = true;
            }
        })

        if (username_exists_satus) {
            setLoading(false);
            setError('Username already exists. Please use another one.');

            return "";
        }

        if (email_exists_status) {
            setLoading(false);
            setError('Email already exists. Please use another one.');

            return "";
        }

        // console.log("++++++++++++++++++++ User collection +++++++++++++++", usernameRef);
        // Check if username already exists
        // db.collection('User')
        // .where('userName','==',username)
        // .get()
        // .then(async snapshot => {
        // Sign up user

        // if (snapshot.empty) {
        await auth.createUserWithEmailAndPassword(emailRef.current.value, passwordRef.current.value)
            .then(userInfo => {
                const userObj = {
                    FollowerIds: [],
                    FollowersIds: [],
                    gamerTag: username,
                    userName: username,
                    userLosses: 0,
                    userWins: 0,
                    userRank: 'Prospect',
                    userProfileImageUrl: '',
                    updateProfileAvatarDeliveredTo: [],
                    email: userInfo.user.email,
                    pushId: '',
                    isOnline: true,
                    followedUsersIds: [],
                    contact: [],
                    blockedUserId: [],
                    userBalance: 0,
                    objectId: userInfo.user.uid,
                    notificationCounts: 0,
                    loginMethod: 'email',
                    twitchlink: "NONE",
                    youtubelink: "NONE",
                    userPictureId: false,
                    userPlayStationOption: true,
                    userXboxOption: true,
                };

                const userObjRealTimeDB = {
                    isOnline: true,
                    loginMethod: 'email',
                    notificationCounts: 0,
                    objectId: userInfo.user.uid,
                    pushId: '',
                    userBalance: 0,
                    userEmail: userInfo.user.email,
                    userGamerTag: username,
                    userLosses: 0,
                    userPictureId: false,
                    userPlayStationOption: true,
                    userProfileImageUrl: '',
                    userRank: 'Prospect',
                    userWins: 0,
                    userXboxOption: true,
                    userTwitchChannelLink: '',
                    userYouTubeChannelLink: '',
                    email: userInfo.user.email,
                    gamerTag: username,
                    followedUsers: [],
                    twitchlink: '',
                    youtubelink: '',
                    userName: username,
                };
                var user = firebase.auth().currentUser;
                user.updateProfile({
                    displayName: username,
                    photoURL: 'https://firebasestorage.googleapis.com/v0/b/fantasysports-7117e.appspot.com/o/default_profile_photo.svg?alt=media&token=d217e767-41e1-4a94-9f71-4a60b99b3403'
                })
                    .then(() => {

                        userObj.userProfileImageUrl = user.photoURL;
                        //Update user Info
                        setFirestoreDocument('User', userObj, userInfo.user.uid)
                        setRealtimeDoc('Users', userObjRealTimeDB, userInfo.user.uid)
                    })

            })
            .catch(err => {
                setLoading(false)

                switch (err.code) {

                    case 'auth/email-already-in-use':
                        setError('Email already exists')
                    case 'auth/invalid-email':
                        setError(err.message)
                        break;
                    case 'auth/weak-password':
                        setError(err.message)
                        break;


                }

            })


        setLoading(false)
        // }

        // })
        // .catch(err =>{
        //     console.log(err.message)
        //     setError(err.message)
        //     setLoading(false)

        // })





    }

    return (
        <>

            <form className="form">
                <div className={`header-content `}>
                    <img className="form-image" src={trophy} alt="" />
                    <p className="form-title pt-4">ClutchTime</p>
                    <p className='form-text f-18'>Please register your details to continue
                        with ClutchTime</p>
                </div>
                <p className="text-danger">{error}</p>

                <div className="input-group">
                    <input ref={usernameRef} onChange={(e) => setUsername(e.target.value)} name="username"
                        style={{ backgroundImage: `url(${user})` }} type="text"
                        placeholder="Gamer Tag" />
                    <input ref={emailRef} name="email" style={{ backgroundImage: `url(${mail})` }} type="Email"
                        placeholder="Email" />
                    <input ref={passwordRef} name="password" style={{ backgroundImage: `url(${password})` }}
                        type="Password" placeholder="Password" />

                </div>

                <button disabled={loading} style={{ background: loading ? '#ffffff' : '' }} type="button" onClick={signUp}><span
                    className='form-btn'>Sign Up</span></button>
            </form>
        </>
    );
}

export default Signup;


