import React, {useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {db} from "../firebase/firebase";
import firebase from "firebase";
import {useCollection} from "react-firebase-hooks/firestore"
import moment from "moment";
import group from "../images/group.png";
import user from "../images/user.svg";

function MessageCard({id, chats, currentUser}) {
    const history = useHistory()
    // Listens on changes to the document
    // Returns an array of objects in the chat sub-collection
    const [messagesSnap] = useCollection(db.collection('ChatRooms').doc(chats.chatRoomId).collection('chat').orderBy('dateTime', 'desc'))

    // Listens on changes to the document
    // Returns an array of objects in the chat sub-collection
    // where current user id is in the deliveredToParticipants property
    const [readMessagesSnap] = useCollection(db.collection('ChatRooms').doc(chats.chatRoomId).collection('chat').where('deliveredToParticipants', 'array-contains', currentUser?.uid))
    const [receiver] = chats.participants.filter((receiverObj) => {
        if (receiverObj.objId !== currentUser.uid)
            return receiverObj

    })
    const [loading] = useState(false);

    const imageRef = useRef();

    let params = useParams();


    const openMessage = () => {

        // Check for unread messages
        if (messagesSnap?.docs.length - readMessagesSnap?.docs.length > 0) {
            // Fetch document and Update deliveredToParticipants field
            db.collection('ChatRooms').doc(id).collection('chat')
                .get()
                .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref
                        .update({deliveredToParticipants: firebase.firestore.FieldValue.arrayUnion(currentUser?.uid)})
                        .then(() => {
                            history.push(`/messages/${id}`)

                        })
                        .catch(e => {
                            console.log(e.message)
                        })

                })

            })

        } else

            history.push(`/messages/${id}`)


    }


    return (
        <>
            <div style={{backgroundColor: chats.chatRoomId === params.id ? '#aaaaaa1c' : '#2B3038'}} onClick={openMessage}
                 id={id}
                 className={`d-flex pointer  message-card user-list-sub-section`}>

                <div className='user-list-thumb-wrapper'>
                    <img ref={imageRef}
                         src={chats.isGroupChat ? chats.groupImageUrl ? chats.groupImageUrl : group : receiver?.userProfileImageUrl ?receiver?.userProfileImageUrl: user}
                         alt=""/>
                </div>
                <div className='ml-3 text-light w-100'>
                    <span>{chats.isGroupChat ? chats.groupChatName : receiver?.gamerTag || receiver?.userName}</span>
                    <span
                        className=' float-right d-flex flex-column align-items-center justify-contents-center space-light'>
        <small>{moment(chats.dateLastUpdated?.seconds * 1000).format("DD/MM/YYYY h:mm")}</small>
        <>
            {
                messagesSnap?.docs.length - readMessagesSnap?.docs.length > 0 &&
                <span
                    className={`message-card-notification text-light mt-1`}>
        <span
            className={`mx-auto mt-auto mb-auto`}>{messagesSnap.docs.length - readMessagesSnap.docs.length}</span>
    </span>
            }
        </>
    </span>
                    <div
                        className="space-light text-break">  {!loading && messagesSnap?.docs[0] !== undefined && messagesSnap?.docs[0]?.data().text !== "" ? messagesSnap?.docs[0]?.data().text : messagesSnap?.docs[0]?.data().isImage ? 'Image' : messagesSnap?.docs[0]?.data().isVideo ? 'Video' : messagesSnap?.docs[0]?.data().isVoiceNote ? 'Voice Note' : ""}</div>

                </div>
            </div>
        </>


    );
}

export default MessageCard;
