import React, {useEffect,useRef, useState} from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import './withdraw.css';
import mobile from '../../images/mobile.png';
import mail from '../../images/mail.svg';
import {useHistory} from 'react-router-dom'
import {useStateValue} from '../../contexts/StateProvider';
import {pushRealTimeData, updateFirebaseDocument, updateFirestoreDocument, updateDocument} from "../../utility/helper";
import logo from '../../images/logo.png';
import axios from 'axios';
import {css} from "@emotion/react";
import $ from 'jquery';


export default function Deposit(props) {
    
    const [{user, userData}, dispatch] = useStateValue()
    const emailRef = useRef()
    const phoneNumberRef = useRef()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [success, setSuccess] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const override = css`
      display: block;
      margin: 0 7%;
      margin-left: auto;
      margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");


    useEffect(() => {
      // setLoading(true);
        const script = document.createElement("script");
        script.src = "https://js.braintreegateway.com/web/dropin/1.33.0/js/dropin.min.js";
        // script.async = true;
        script.onload = () => braintree();
        document.body.appendChild(script);
        // setLoading(false);
    }, [])

    const braintree = ()=>{
      // setTimeout(() => {
        // },200)
          setLoading(false);

        var button = document.querySelector('#submit-button');
        console.log("BTN ",button)

        window.braintree.dropin.create({
          // Insert your tokenization key here
          authorization: 'sandbox_fs5m7cxd_2fyn5wfc25kf2kv5',
          container: '#dropin-container',
            paypal: {
            flow: 'vault',
            amount: props.amount,
            currency: 'USD'
          },
        }, function (createErr, instance) {
          button.addEventListener('click', function (e) {
            e.preventDefault();
            instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
              // When the user clicks on the 'Submit payment' button this code will send the
              // encrypted payment information in a variable called a payment method nonce
              console.log("err ",requestPaymentMethodErr)
              console.log("Nounce ",payload)

              var amount = parseInt(props.amount);

              let payeeArr = []

              payeeArr.push({ "amount": { "currency_code": "USD", "value": amount }, "payee": { "email_address": userData.email } })

              var data = new FormData();
              data.append('device', 'Web');
              data.append('purchase_units', JSON.stringify(payeeArr));
              data.append('login_user_id', userData?.objectId);

              $.ajax({
                method: 'post',
                url: 'http://www.swicb.com/wallettransfer/lara/public/index.php/api/create-payout',
                headers: { 'content-type': 'application/json' },
                data: data
              }).done(function(result) {
                // Tear down the Drop-in UI
                
                var resultData = result.data;
                if (resultData.status_code == 200) {
                  var responseData = resultData.data;
                  var batchHeader = responseData.batch_header;

                  if (batchHeader.batch_stat == "SUCCESS") {
                      var amount = batchHeader.amount.value;

                      updateFirestoreDocument('User', user.uid, {userBalance: parseInt(userData.userBalance) + amount}).then(() => {
        
                          //Update userData balance in firebase userData document
                          updateFirebaseDocument('Users', user.uid, {userBalance: parseInt(userData.userBalance) + amount})
                          .catch(e => {
                              console.log(e);
                              updateFirestoreDocument('User', user.uid, {userBalance: parseInt(userData.userBalance)})
                          })
                          
                          userData.userBalance += amount;
                          dispatch({
                              type:"SET_USER_DATA",
                              userData
                          })
                          setLoading(false)
                          setSuccess('Deposit successful')
                          props.onHide()
        
                      })
                      .catch(e => {
                              console.log(e)
                              setError('Deposit unsuccessful')
                          }
                      )
                  }
                  else{
                    setError('Deposit unsuccessful')
                  }
                  // $('#checkout-message').html('<h1>Success</h1><p>Your Drop-in UI is working! Check your <a href="https://sandbox.braintreegateway.com/login">sandbox Control Panel</a> for your test transactions.</p><p>Refresh to try another transaction.</p>');
                
                } else {
                  setError('Deposit unsuccessful')
                  console.log(result);
                  // $('#checkout-message').html('<h1>Error</h1><p>Check your console.</p>');
                }
              });



            });
          });
        });
        
        // window.braintree.dropin.create({
        //     authorization: 'sandbox_fs5m7cxd_2fyn5wfc25kf2kv5',
        //     container: '#dropin-container',
        //     applePay: {
        //       displayName: 'Merchant Name',
        //       paymentRequest: {
        //       label: 'Localized Name',
        //         total: props.amount
        //       }
        //     },
        //     paypal: {
        //       flow: 'checkout',
        //       amount: props.amount,
        //       currency: 'USD'
        //     },
        //    paypalCredit: {
        //      flow: 'checkout',
        //      amount: props.amount,
        //      currency: 'USD'
        //     },
        //     venmo: true
        //   }, function (err, dropinInstance) {
        //     console.log("dropinInstance =>", dropinInstance)
        //     console.log("err =>", err)
        //     dropinInstance.requestPaymentMethod(function (err, payload) {
        //       // Submit payload.nonce to your server
        //       console.log("Payload =>", payload)
        //       console.log("err =>", err)
        //     });
        //     // button.addEventListener('click', function () {
        //     // })
        //     // Set up a handler to request a payment method and
        //     // submit the payment method nonce to your server
        //   });
          // setLoading(false);
    }

    return (
        <>
        <div>
            
            <form id="payment-form" action="/route/on/your/server" method="post">
              <div className={`header-content `} style={{marginBottom:"40px"}}>
                  <img className="form-image" src={logo} alt="" style={{height:"100px",width:"100px"}}/>
                  <p className="form-title pt-4" style={{color:"#000000"}}>{`Amount : ${props.amount} USD`}</p>
              </div>
                {/* <script src="https://js.braintreegateway.com/web/dropin/1.33.0/js/dropin.min.js"
                    data-braintree-dropin-authorization="sandbox_fs5m7cxd_2fyn5wfc25kf2kv5"
                    data-locale="en_EN"
                    data-payment-option-priority='["googlePay","paypal","card", "paypalCredit"]'
                    ></script> */}

                <div id="dropin-container">{(loading)&&<PuffLoader css={override}  loading={loading} size={24} color={color} />}</div>
                <button id="submit-button">Submit payment</button>
                <input type="hidden" id="nonce" name="payment_method_nonce"/>
                <input type="hidden" id="amount" name="amount"/>
            </form>
      </div>
</>
    );
}
