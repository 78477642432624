import Signup from './sections/signup/signup'
import Events from "./sections/events/events";
import EventDetails from "./sections/events/eventDetails";
import 'react-notifications-component/dist/theme.css'
import { FormProvider } from "./contexts/formContext";
import { Route, Switch } from 'react-router-dom'
import { EventProvider } from "./contexts/eventsContext";
import { LoaderProvider } from "./contexts/loaderContext";
import UserProfile from "./sections/profile/userProfile";
import Message from "./sections/message/message.js";
import { ChatProvider } from "./contexts/messageContext";
import JoinedEvents from "./components/joinedEvents";
import CreatedEvents from "./components/createdEvents";
import CreateSchedule from "./components/createSchedule";
import Withdraw from "./components/withdraw";
import Deposit from "./components/deposit";
import { TransactionProvider } from "./contexts/transactionContext";
import Home from "./Home";
import React, { useEffect } from 'react';
import { auth, db } from './firebase/firebase';
import { useStateValue } from './contexts/StateProvider';
import HowItWorks from "./sections/howItWorks/howItWorks";
import About from "./sections/about/about";
import Contact from "./sections/contactUs/contact";
import Tournament from './components/tournament';
import UseTerms from './sections/useTerms/useTerms';
import PrivacyPolicy from './sections/privacyPolicy/privacyPolicy';
import HowItWork from './sections/HowItWork/HowItWork';
// import MemberEvents from "./sections/events/MemberEvents";

function App() {

    const [{ user }, dispatch] = useStateValue()


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                dispatch({
                    type: "SET_USER",
                    user
                })

                db.collection('User').where('email', "==", user.email)
                    .onSnapshot((snapshot) => {
                        const userArr = snapshot.docs.map(doc => doc.data())
                        const userData = userArr?.find(b => { return b })
                        // console.log(userData);
                        dispatch({
                            type: "SET_USER_DATA",
                            userData
                        })

                    })

            }
        })
        return () => unsubscribe();
    }, [])

    return (
        <>
            <LoaderProvider>

                <ChatProvider>



                    <FormProvider>



                        <Switch>

                            <EventProvider>
                                <TransactionProvider>

                                    <>
                                        <Route exact path="/events" component={Events} />
                                        {user?.uid && <Route path="/eventDetails/:id" component={EventDetails} />}
                                        <Route path="/signup" component={Signup} />
                                        <Route path="/about" component={About} />
                                        <Route path="/how-it-works" component={HowItWorks} />
                                        <Route path="/contact" component={Contact} />
                                        <Route exact path="/useterms" component={UseTerms} />
                                        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                                        <Route exact path="/how-does-it-work" component={HowItWork} />

                                        {user?.uid && <Route exact path="/user/:id/created-events" component={CreatedEvents} />}
                                        {user?.uid && <Route exact path="/user/:id" component={UserProfile} />}
                                        {user?.uid && <Route exact path="/user/:id/create-schedule" component={CreateSchedule} />}
                                        {user?.uid && <Route exact path="/user/:id/joined-events" component={JoinedEvents} />}
                                        {user?.uid && <Route exact path="/user/:id/tournament/:eventId" component={Tournament} />}
                                        {user?.uid && <Route exact path="/user/:id/manage-funds" component={Deposit} />}
                                        {/* {user?.uid && <Route exact path="/user/:id/withdraw" component={Withdraw} />} */}
                                        {user?.uid && <Route exact path="/messages" component={Message} />}

                                        {user?.uid && <Route path="/messages/:id" component={Message} />}

                                        {!user?.uid ? <Route exact path="/" component={Home} /> : <Route exact path="/" component={Events} />}

                                    </>
                                </TransactionProvider>




                            </EventProvider>

                        </Switch>
                    </FormProvider>
                </ChatProvider>

            </LoaderProvider>
        </>



    );
}

export default App;
