import React from 'react';
import '../events/events.css'
import search from '../../images/search.svg';

function Search({props,functionHandler}) {

    




    return (
            <div className='position-relative search-input'>
                <form className="d-flex">
                    <input  onChange={functionHandler} className='' name="search" style={{backgroundImage: `url(${search})`,backgroundColor: props}} type="text"
                           placeholder="Search"/>

                </form>


        </div>
    );
}

export default Search;
